import { useState, useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { useHttpClient } from '../shared/http-hook';

import { hashString } from 'react-hash-string';

import LoadingSpinner from './LoadingSpinner';
import { Row, Col, Button, Form } from 'react-bootstrap';

import Countdown, { zeroPad } from 'react-countdown';

import axios from 'axios';

const Model = () => {
    const { sendRequest, isLoading } = useHttpClient();
    const nick = useParams().nick;

    const [modelInfo, setModelInfo] = useState();
    const [displayedImage, setDisplayedImage] = useState();

    const [step, setStep] = useState(0);
    const [maxStep, setMaxStep] = useState(2)
    const [email, setEmail] = useState();
    const [years, setYears] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [affData, setAffData] = useState();

    const [userInfo, setUserInfo] = useState();

    const username = Math.random().toString(36).substr(2, 9)
    
    const [dateVar, setDateVar] = useState(Date.now() + (Math.random() * 300000))

    const getInfo = async () => {
        try {
            const response = await sendRequest('https://geolocation-db.com/json/');
            setUserInfo(response)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchModel = async () => {
        const response = await sendRequest('../data/users.json');
        const filter = response.find((item) => item.nick === nick)

        console.log(filter)

        if(!filter) {
            alert('We could not find the requested model!')
            window.location.href = "/"
            return;
        }

        setModelInfo(filter)
        setDisplayedImage(filter.image)
    }

    useEffect(() => {
        fetchModel()
        getInfo()
    }, [nick])

    const renderer = ({ minutes, seconds, completed }) => {
          return (
            <div className="model-main-count">
            <span>{zeroPad(minutes)}</span><span>:</span><span>{zeroPad(seconds)}</span>
            </div>
          );
    };

    // server apis
    const createMailWizz = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/createmailwizz',
            'POST',
            JSON.stringify({
                username: username,
                email: email,
                years: years,
                country_code: userInfo ? userInfo.country_code : null,
                aff_sub2: aff_sub2,
                AFFID: aff_sub5
            }),
            {
              'Content-Type': 'application/json'
            }
          );            
          console.log(response)

        } catch (err){
            console.log(err);
        }
    }

    const createDa = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createda',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: transaction_id,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );            

            const formatdata = response.data.split("|");
            const url = formatdata[3];
            
            if(url && url.startsWith('https://')){
                setAffData(url)
            } else {
                createTrawle()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createMirelia = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createmirelia',
              'POST',
              JSON.stringify({
                  email: email,
                  ip: userInfo.IPv4,
                  aff: transaction_id
              }),
              {
                'Content-Type': 'application/json'
              }
            );            
            
            const url = response.responsedata.url;
            
            if(url && url.startsWith('https://')){
                setAffData(url)
            } else {
                createDa()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createTrawle = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createtrawle',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: transaction_id,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );          
            
            const url = response.data.login_url;

            if(url && url.startsWith('https://')){
                setAffData(url)
            } else {
                createPure()
            }

          } catch (err){
              console.log(err);
          }
    }

    function randomGenerator() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      
        for (var i = 0; i < 10; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text.toLowerCase();
    }

    var randomstring = randomGenerator();
    var randomusername = randomGenerator();
    const createPure = async () => {
        try {
            const response = await sendRequest(
                'https://gorillamediallc.com/bangbasetesting/api/createpure',
                'POST',
                JSON.stringify({
                    uname: randomusername,
                    email: email,
                    password: randomstring,
                    ip_address: userInfo.IPv4,
                    city: userInfo.city,
                    state: userInfo.state,
                    aff: transaction_id,
                    aff_sub2: aff_sub2
                }),
                {
                  'Content-Type': 'application/json'
                }
              );          
              
              if(response.data !== "\"OK\""){
                createMirelia()
              }
        } catch (err) {
            console.log(err)
        }
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const transaction_id = searchParams.get("aff_sub");
    const aff_sub2 = searchParams.get("aff_sub2");
    const aff_sub3 = searchParams.get("aff_sub3");
    const aff_sub4 = searchParams.get("aff_sub4");
    const aff_sub5 = searchParams.get("aff_sub5");
    
    const saveFileUsers = async (data) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/savefileusers',
            'POST',
            JSON.stringify(data),
            {
              'Content-Type': 'application/json'
            }
          );
    
        } catch (err) {}
    }

    const [usersDB, setUsersDB] = useState([])
    const checkDB = async (email) => {
        const response = await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/stored/data/usersdb.json',
            'GET',
            null,
            {
              'Content-Type': 'application/json'
            }        
        )

        setUsersDB(response)
        const result = response.find((user) => user.email === email)

        let checked;
        if(result) {
            checked = true;
        } else {
            checked = false;

            const formObject = {
                email: email
            }
            
            setUsersDB((prev) => [...prev, formObject])
        }

        return checked
    }

    const emailAPI = async (email) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/checkemailapi',
              'POST',
              JSON.stringify({
                  email: email
              }),
              {
                'Content-Type': 'application/json'
              }
            );


            return response.dataresponse;      
          } catch (err) {
              console.log(err)
          }
    }

    const createHandler = (info) => {
        console.log('axios call body empty')
    };

    const submitForm = async (e) => {
        e.preventDefault()

        setLoading(true)

        if((step === 1 && !years) || (step === 1 && years < 18)){
            setError('Age are required! Must be older than 18 years!')
            setLoading(false)
            return;      
        } 

        if(step === 1 && checkEmail(email) === false){
                setError('Email is not valid!')
                setLoading(false)
                return;      
        } 

        if(step === 1 && email){
            const emailapi = await emailAPI(email);

            if(emailapi && emailapi.valid === false && emailapi.recent_abuse === true && emailapi.deliverability === "low"){
                setError('Email did not pass the request!')
                setLoading(false)
                window.open('https://www.gorillatrk1.com/cmp/2D2DH/RF39C/', '_blank');
                return; 
            }
        }

        const emailDB = await checkDB(email);
        if(step === 1 && emailDB === true){
            setError('Email is already in use!')
            setLoading(false)
            return;             
        }

        try {
            const user = {
                username: username,
                email: email,
                years: years
            }

            localStorage.setItem('user', JSON.stringify(user));
            createHandler(user);

            // postback
            if(transaction_id){
                const hash = hashString(email);
                localStorage.setItem('transaction_id', transaction_id)
                await axios.get(`https://www.gorillatrk1.com/?nid=1737&transaction_id=${transaction_id}&adv1=${hash}&sub2=${aff_sub2}&sub3=${aff_sub3}`)    
            }

            localStorage.setItem('aff_sub2', aff_sub2)
            localStorage.setItem('aff_sub3', aff_sub3)
            localStorage.setItem('aff_sub4', aff_sub3)

            createMailWizz()
            createDa()
            
            saveFileUsers(usersDB)

            setError('')
            setLoading(false)
            continueStep(e)
        } catch (err) {
            setError(err)
        }
    }

    function checkEmail(str) {
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);
    }

    const continueStep = async (e) => {
        e.preventDefault()

        if(step < maxStep || !error){
            setLoading(true)

            setTimeout(function(){
                    setStep(step + 1);
                    setError('')
                    setLoading(false)
            }, 2000);

            if(modelInfo.images[step].src) {
                setDisplayedImage(modelInfo.images[step].src)
            }
        }

    }

    const lastStep = () => window.open(affData, '_blank' );

    const randomMiles = Math.random() * (5 - 1) + 1;
    const [randomM, setRandomM] = useState(randomMiles)

    return (
        <>
        {loading && <LoadingSpinner asOverlay />}

        {!loading && modelInfo && (
            <>
            <Row className="g-0">

                <Col sm={6} className="member-model-img position-relative" style={{  
                backgroundImage: "url(" + displayedImage + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                opacity: 0.75
                }}>

                    <div className="member-model-inf">
                        <h3>{modelInfo?.name}</h3>
                        <p>{modelInfo?.age} <span>years old</span></p>
                        <p>{randomM?.toFixed()} <span>miles away</span></p>
                    </div>

                </Col>

                <Col sm={6} className="position-relative">
                    <div className="model-main-form">
                            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} className="model-logo-main"  alt="logo model" />

                            <hr />


                            <div className="model-main-step">
                            {step === 0 ? (
                                <>
                                <h1>Welcome to the BEST<span className="custom-purplemain">hookup</span> site!</h1>

                                <h4>Free registration closes in..</h4>
                                <Countdown date={dateVar} renderer={renderer} />

                                <hr />

                                <h2 className="mb-4">Ready to get some action?</h2>
                                <Button variant="dark" size="lg" className="button-prototype w-100 p-3" onClick={continueStep}>ACCEPT</Button>
                                </>
                            ) : step === 1 ? (
                                <>
                                <Countdown date={dateVar} renderer={renderer} />

                                <hr />

                                <h1>Enter your Age and Email to join for free!</h1>
                                <Form onSubmit={submitForm}>
                                <Form.Group className="mb-3" controlId="formBasicAge">
                                    <Form.Label>Age:</Form.Label>
                                    <Form.Control type="text" name="age" maxLength="2" autoComplete="off" value={years ? years : ''} placeholder="Enter age" onChange={(e) => setYears(parseInt(e.target.value))} />
                                </Form.Group>                                  

                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control type="email" name="email" autoComplete="off" defaultValue={aff_sub4} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>   
                                <div className="info-form-imp">                              
                                <small>By proceeding I certify I am 18 years or older, agree to the <a href="/tos" target="_blank">TOS</a>, <a href="/privacypolicy" target="_blank">Privacy Policy</a> , sign up for newsletters and agree to receive messages.</small>     
                                </div> 

                                <Button variant="dark" size="lg" className="button-prototype w-100 p-3" type="submit">Join for Free</Button>
                                </Form>
                                </>
                            ) : step === 2 ? (
                                <>
                                <h2>Congratulations!</h2>
                                <p>Your account has been approved.</p>
                                
                                <a href={`https://bangbase.app/dashboard/member/${nick}`}>
                                    <Button variant="dark" className="button-prototype w-100 p-3" onClick={() => lastStep()}> ENTER BANGBASE </Button>
                                </a>
                                </>
                            ) : null}

                            {error ? (
                                        <>
                                        <div className="alert alert-danger mt-4" style={{ background: 'red', color: 'white', borderColor: 'red' }}> <i className="fa-solid fa-triangle-exclamation"></i> {error} </div>
                                        </>
                            ) : ''}
                            </div>
                    </div>

                </Col>
            </Row>
            </>
        )}
        </>
    )
}

export default Model;