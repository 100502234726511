import React, { useState, useEffect } from 'react';

import { useHttpClient } from '../../shared/http-hook';

import DashboardStartMessage from './DashboardStartMessage';
import { Row, Col, Button } from 'react-bootstrap';

import IframeModal from '../helpers/IframeModal';

const DashboardMessages = () => {
    const local = JSON.parse(localStorage.getItem('user'))
    const { sendRequest, isLoading } = useHttpClient();
    const [data, setData] = useState();
    
    const [iframeBtn, setIframeBtn] = useState(false);

    const getMessages = async () => {
        try {
            const response = await sendRequest('https://gorillamediallc.com/bangbasetesting/api/getusersitems');
            const filter = response.user_items.filter((item) => item.type === "messages" && item.member === local.username)
            setData(filter.reverse())
        } catch (err) {}
    }

    useEffect(() => {
        getMessages()

        const interval = setInterval(() => {
            if(localStorage.getItem('honplayed') === "true"){
                getMessages()
            }

        }, 10000);
        return () => clearInterval(interval);       
    }, [sendRequest]);

    const [customFullscreen, setCustomFullscreen] = useState(false); 

    return (
        <>
        {localStorage.getItem('honplayed') === "true" ? (
            <>
            <IframeModal display={iframeBtn} strictly='on' custom_fullscreen={customFullscreen} onClose={() => {
                setIframeBtn(false)
                setCustomFullscreen(false)
                localStorage.setItem('iframeactive', false)
            }} />

            <div className="dashboard-right addbordercontent position-relative">

            <h3 className="db-title"><i className="fa-solid fa-inbox"></i> My messages</h3> <hr />

            {data && data.length < 1 && (
                <>
                <p>No results!</p>
                </>
            )}


            {data && data.length > 10 && (
                <>
                        <div className="blured-message">
                                You've reached your limit - verify your age to unlock all messages!
                                <center>
                                <Button variant="dark btn-sm m-2" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }}>
                                Verify now
                               </Button>
                                </center>
                        </div>
                </>
            )}

            {data && data.map((item, index) => {
                let blured;
                if(index > 10){
                    blured = "blured-row";
                } else {
                    blured = "";
                }

                return (
                    <React.Fragment key={index}>
                    <div className={`profile-infoblock position-relative ` + blured}>

                        {blured === "blured-row" ? (
                            <>
                                <a onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }} target="_blank" rel="noreferrer">
                                
                                <Row>
                                    <Col sm={2}>
                                        <center>
                                        <img src={item.content.image} className="img-fluid" alt={item.nick} style={{ maxHeight: "50px" }} />
                                        </center>
                                    </Col>
                                    <Col sm={10}>
                                        <div className="profile-infoblockcontent">
                                                <p>{item.content.nick}</p>
                                                <span>{item.content.message}</span>
                                                <small>{}</small>
                                        </div>
                                    </Col>
                                </Row>
                                </a>                            
                            </>
                        ) : (
                            <>
                                <a onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }} target="_blank" rel="noreferrer">
                                <Row>
                                    <Col sm={2}>
                                        <center>
                                        <img src={item.content.image} className="img-fluid" alt={item.nick} style={{ maxHeight: "50px" }} />
                                        </center>
                                    </Col>
                                    <Col sm={10}>
                                        <div className="profile-infoblockcontent">
                                                <p>{item.content.nick}</p>
                                                <span>{item.content.message}</span>
                                                <small>{}</small>
                                        </div>
                                    </Col>
                                </Row>
                            </a>                               
                            </>
                        )}
                    </div>
                    </React.Fragment>
                )
            })}

            </div>     
            </>      
        ) : (
            <>
            <DashboardStartMessage /> 
            </>
        )}
        </>
    )
}

export default DashboardMessages;