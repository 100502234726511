import React,  { useState, useEffect} from 'react';

import { useParams, Link } from 'react-router-dom';
import { useHttpClient } from '../../shared/http-hook';
import LoadingSpinner from '../LoadingSpinner';

import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';

import IframeModal from '../helpers/IframeModal';
import CustomModal from '../helpers/CustomModal';

const DashboardMemberProfile = () => {
    const nick = useParams().nick;

    const { sendRequest } = useHttpClient();

    const [userData, setUserData] = useState();
    const [userPhotos, setUserPhotos] = useState();
    const [userVideos, setUserVideos] = useState();
    const [loading, setLoading] = useState(false);

    const [iframeBtn, setIframeBtn] = useState(false);

    const [customBtn, setCustomBtn] = useState(false);
    const [customBtnType, setCustomBtnType] = useState();   

    const getUserData = async () => {
        setLoading(true)

        try {
            const response = await sendRequest('../../data/users.json');
            const result = response.filter(item => {
                return item.nick === nick
            })
            
            setUserData(result[0])
            setUserPhotos(result[0].images)
            setUserVideos(result[0].videos)

            setTimeout(function(){
                setLoading(false)
            }, 2000);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getUserData();
    }, []);

    const aff_sub = localStorage.getItem('transaction_id')
    const aff_sub1 = localStorage.getItem('aff_sub1')
    const aff_sub2 = localStorage.getItem('aff_sub2')
    const aff_sub3 = localStorage.getItem('aff_sub3')
    const aff_sub4 = localStorage.getItem('aff_sub4')
    const aff_sub5 = localStorage.getItem('aff_sub5')

    const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}&transaction_id=${aff_sub}`;

    const [customFullscreen, setCustomFullscreen] = useState(false);  

    return (
        <>
        <div className="dashboard-right addbordercontent">
            {loading && <LoadingSpinner asOverlay />}

            <IframeModal display={iframeBtn} strictly='on' custom_fullscreen={customFullscreen} onClose={() => {
                setIframeBtn(false)
                setCustomFullscreen(false)
                localStorage.setItem('iframeactive', false)
            }} />

            <CustomModal display_custom={customBtn} type={customBtnType} onClose={() => {
                setCustomBtn(false)
                setCustomBtnType(null)
            }} />   

            {!loading && userData && (
                <>
                <div className="profile">
                    <section>
                        <div className="float-end">
                            
                            <Button variant="dark" className="m-2" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                            }}> Send message </Button>

                            <a href={`https://www.gorillatrk1.com/3J67C/QTDKJQ/${link}`} target="_blank">
                                <Button variant="dark" className="m-2"> Go live </Button>
                            </a>
                        </div>

                        <Row>
                            <Col sm={3} className="profile-p">
                                <img src={userData.image} className="img-fluid img-thumbnail w-100" alt={userData.image} /> 
                            </Col>
                            <Col sm={9}>
                                <h2>@ {userData.nick}</h2>
                                <p>{userData.name}</p>
                                <p>{userData.status === "online" ? <span className="online"> Online now </span> : <span className="offline"> Offline </span>}</p>
                            </Col>
                        </Row>
                    </section>

                    <Tabs defaultActiveKey="photos">
                        <Tab eventKey="photos" title="PHOTOS">

                            <Row className="m-1">
                            {userPhotos && userPhotos.map((photo, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Col sm={4} className="p-1">
                                                <img src={photo.src} className="img-fluid w-100 h-100" alt={photo.src} />
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                            </Row>

                            <center>
                                <Button className="m-4" variant="dark" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }}>
                                    Request more photos
                                </Button>
                            </center>
                        </Tab>
                        <Tab eventKey="videos" title="VIDEOS">
                            <Row className="m-1">
                            {userVideos && userVideos.map((video, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Col sm={4} className="p-1">
                                                <div align="center" className="embed-responsive embed-responsive-16by9">
                                                    <video controls className="embed-responsive-item img-fluid">
                                                        <source src={video.src} type="video/mp4" />
                                                    </video>
                                                </div>
                                        </Col>
                                    </React.Fragment>
                                )
                            })}
                            <Col sm={4}>
                                <Link to="#" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }}>
                                <div className="blocked-content">
                                    <h1><i className="fa-solid fa-lock"></i></h1>
                                    <p>Locked</p>
                                </div>
                                </Link>
                            </Col>
                            <Col sm={4}>
                                <Link to="#" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }}>
                                <div className="blocked-content">
                                    <h1><i className="fa-solid fa-lock"></i></h1>
                                    <p>Locked</p>
                                </div>
                                </Link>
                            </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </div>
                </>
            )}
        </div>
        </>
    )
}

export default DashboardMemberProfile;