import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import { useHttpClient } from '../../shared/http-hook';

import { Modal, Form, Button } from 'react-bootstrap';

import axios from 'axios';

import LoadingSpinner from '../LoadingSpinner';

const EmailModal = (props) => {
    const { sendRequest } = useHttpClient();

    const [formLoading, setFormLoading] = useState(false);

    const [email, setEmail] = useState();
    const [years, setYears] = useState();

    const [userInfo, setUserInfo] = useState();
    const username = Math.random().toString(36).substr(2, 9)

    const [searchParams, setSearchParams] = useSearchParams();
    const transaction_id = searchParams.get("aff_sub");
    const aff_sub1 = searchParams.get("aff_sub1");
    const aff_sub2 = searchParams.get("aff_sub2");
    const aff_sub3 = searchParams.get("aff_sub3");
    const aff_sub4 = searchParams.get("aff_sub4");
    const aff_sub5 = searchParams.get("aff_sub5");

    const handleCloseModal = () => {
        props.onClose();
    }

    const getInfo = async () => {
        try {
            const response = await sendRequest('https://geolocation-db.com/json/');
            setUserInfo(response)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getInfo()
    }, []);

    function openInNewTab(href) {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: href,
        }).click();
    }


    // global 
  function getRandomBirthdate(age) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      
      const birthYear = currentYear - age;
    
      const birthMonth = Math.floor(Math.random() * 12) + 1;
    
      const birthDay = Math.floor(Math.random() * 28) + 1;
    
      const formattedDate = `${birthMonth}/${birthDay}/${birthYear}`;
    
      return formattedDate;
  }
    
  const globalLead = async (url, email) => {
      const randomBirthdate = years ? getRandomBirthdate(years) : null;

      try {
          const response = await axios.get(`https://prestigeww.waypointsoftware.io/capture.php?xAuthentication=5feca4f1951112834804944ccbf5f3f4&email=${email}&PostURL=${url}&DOB=${randomBirthdate}`)
      } catch (err) {
          console.log(err)
      }
  }

  const createFlirtRevenue = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createflirtrevenue',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );            
  
          } catch (err){
              console.log(err);
          }
    }

    const createMailWizz = async () => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/createmailwizz',
            'POST',
            JSON.stringify({
                username: username,
                email: email,
                years: years,
                country_code: userInfo ? userInfo.country_code : null,
                aff_sub2: aff_sub2,
                AFFID: aff_sub5
            }),
            {
              'Content-Type': 'application/json'
            }
          );            
          console.log(response)

        } catch (err){
            console.log(err);
        }
    }

    const createMailWizzCustom = async (list) => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/createmailwizzcustom',
            'POST',
            JSON.stringify({
                username: username,
                email: email,
                years: years,
                country_code: userInfo ? userInfo.country_code : null,
                aff_sub2: aff_sub2,
                AFFID: aff_sub5,
                list: list
            }),
            {
              'Content-Type': 'application/json'
            }
          );            
          console.log(response)

        } catch (err){
            console.log(err);
        }
    }

    // Cascade
    const createDa = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createda',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );            

            const formatdata = response?.data?.split("|");
            const url = formatdata[3];
            
            if(url && url.startsWith('https://')){
                window.location.href = 'http://bangbase.app';
                openInNewTab(url)
            } else {
                await createMoar()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createMoar = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createmoar',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            const moarCount = await getMoarCount();
                        
            if(response.data && response.data.data.auto_login.startsWith('https://') && moarCount < 150){
                // await axios.get(`https://www.gorillatrk1.com/?nid=1737&transaction_id=${aff_sub1}&oid=499`)    

                window.location.href = 'http://bangbase.app';
                openInNewTab(response.data.data.auto_login)

                await addMoarCount()
            } else {
                await createMirelia()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createMirelia = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createmirelia',
              'POST',
              JSON.stringify({
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1
              }),
              {
                'Content-Type': 'application/json'
              }
            );    
            
            console.log(response)

            if(response.responsedata) {
                const url = response.responsedata.url;
                const status = response.responsedata.status;

                if(url && url.startsWith('https://')){
                    window.location.href = 'http://bangbase.app';
                    openInNewTab(url)

                    switch (status) {
                        case "success":
                          await createMailWizzCustom("nl44891yxpf27")

                          break;
                        case "success_rec":
                          await createMailWizzCustom("vm33308k1a2c5")

                          break;
                        default:
                          return;
                    }
                      
                } else {
                    await createFreeFlirtz()
                }
            } else {
                await createFreeFlirtz()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createFreeFlirtz = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createfreeflirtz',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'http://bangbase.app';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createHornySwipe()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createHornySwipe = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createhornyswipe',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'http://bangbase.app';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createNaughtySwipe()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createNaughtySwipe = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createnaughtyswipe',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2

              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'http://bangbase.app';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createTrawle()
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createTrawle = async () => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createtrawle',
              'POST',
              JSON.stringify({
                  username: username,
                  email: email,
                  ip: userInfo.IPv4,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );          
            
            if(response.data && response.data.login_url.startsWith('https://')){
                window.location.href = 'http://bangbase.app';
                openInNewTab(response.data.login_url)
            } else {
                alert('Email did not pass the cascade!')
                window.open('https://www.gorillatrk1.com/3J67C/CHD91P/', '_blank');
                return; 
            }

          } catch (err){
              console.log(err);
          }
    }

    function checkEmail(str) {
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);
    }

    const emailAPI = async (email) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/checkemailapi',
              'POST',
              JSON.stringify({
                  email: email
              }),
              {
                'Content-Type': 'application/json'
              }
            );


            return response.dataresponse;      
          } catch (err) {
              console.log(err)
          }
    }

    const saveFileUsers = async (data) => {
        try {
          await axios.post(
            'https://gorillamediallc.com/bangbasetesting/api/savefileusers',
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
      
        } catch (err) {}
      };

      const [usersList, setUsersList] = useState([])
      const fetchUsers = async () => {
        try {
          const response = await axios.get('https://gorillamediallc.com/bangbasetesting/stored/data/usersdb.json', {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          setUsersList(response.data)
        } catch (err) {
          console.log(err)
        }
      }
      useLayoutEffect(() => {
        fetchUsers();
      }, []);
      
      const checkDB = async (email) => {
        let checked;

        try {
          const result = usersList.find((user) => user.email === email);
      
          if (result) {
            checked = true;
          } else {
            checked = false;
      
            const formObject = {
              email: email
            };
      
            usersList.push(formObject)
          }
      
        } catch (err) {
          console.log(err);
        }

        return checked;
    };      


    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        if(!years && years < 18){
            alert('Age are required! Must be older than 18 years!')
            return;      
        } 

        // step email
        if(checkEmail(email) === false){
                alert('Email is not valid!')
                return;      
        } 

        if(email){
            const emailapi = await emailAPI(email);

            if(emailapi && (emailapi.valid === false || emailapi.recent_abuse === true || emailapi.deliverability === "low")){
                alert('Email did not pass the request!')
                window.open('https://www.gorillatrk1.com/3J67C/CHD91P/', '_blank');
                return; 
            }
        }

        const emailDB = await checkDB(email);
        if(emailDB === true){
            alert('Email is already in use!')
            return;             
        }

        try {
            setFormLoading(true)

            const user = JSON.parse(localStorage.getItem('user')) || {};
            user.username = username;
            user.email = email;
            user.years = years;
            
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('emailmodal', true)

            // postback
            if(transaction_id){
                const hash = hashString(email);
                localStorage.setItem('transaction_id', transaction_id)
                await axios.get(`https://www.gorillatrk1.com/?nid=1737&transaction_id=${transaction_id}&adv1=${hash}&sub2=${aff_sub2}&sub3=${aff_sub3}`)    
            }

            await saveFileUsers(usersList)

            await globalLead(window.location.hostname, email)
            await createFlirtRevenue()

            await createDa()      
            await createMailWizz()      

            setFormLoading(false)
        } catch (err) {
            setFormLoading(false)
            console.log(err)
        } finally {
            setTimeout(() => {
                setFormLoading(false)
                window.location.href = "/"
            }, 5000);
        }
    }

    return (
        <>
        <Modal show={props.show} onHide={() => handleCloseModal()} className="custom_adsmodal_split">
            <Modal.Header closeButton>
            <Modal.Title>Want nudes in your inbox?</Modal.Title>
            </Modal.Header>
            <Modal.Body className='position-relative'>
                {formLoading && <LoadingSpinner asOverlay={true} />}
                <Form onSubmit={handleEmailSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicAge">
                        <Form.Label>Age:</Form.Label>
                        <Form.Control type="text" name="age" maxLength="2" className="split_input" autoComplete="off" value={years ? years : ''} placeholder="Enter age" onChange={(e) => setYears(parseInt(e.target.value))} />
                    </Form.Group>                                  

                    <Form.Group className="mb-3" controlId="email_input">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" name="email" className="split_input" autoComplete="off" value={email ? email : ''} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>   

                    <p className='terms-footer-custom'>
                    By proceeding I certify I am 18 years or older, agree to the <a href="https://bangbase.app/dashboard/terms" target="_blank">TOS</a> and <a href="https://bangbase.app/dashboard/privacypolicy" target="_blank">Privacy Policy</a>
                    </p>

                    <Button type="submit" variant="dark" size="lg" className='custom-btn-theme'> Sign me up! </Button>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default EmailModal;