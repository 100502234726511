import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Row, Col, Button } from 'react-bootstrap';

import { useHttpClient } from '../../shared/http-hook';
import LoadingSpinner from '../LoadingSpinner';

import IframeModal from '../helpers/IframeModal';

const DashboardMembers = () => {
    const { sendRequest } = useHttpClient();
    
    const [usersData, setUsersData] = useState([]);
    const [userLength, setUserLength] = useState();
    const [loading, setLoading] = useState(false);

    const limit = 20;
    const [startLimit, setStartLimit] = useState(0);
    const [endLimit, setEndLimit] = useState(20)

    const [iframeBtn, setIframeBtn] = useState(false);

    const getUsersData = async () => {

        setLoading(true)
        setActiveTagName('')

        try {
            const response = await sendRequest('../data/users.json');

            setUserLength(response.length)

            const result = response.slice(0, endLimit)
            setUsersData(result)

            setTimeout(function(){
                setLoading(false)
            }, 2000);
        } catch (err) {
            console.log(err);
        }
    }

    const [tags, setTags] = useState([]);
    const getTags = async () => {
        setLoading(true)

        try {
            const response = await sendRequest('https://gorillamediallc.com/bangbasetesting/stored/data/tags.json')
            setTags(response)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getUsersData();
        getTags()
    }, []);

    const [activeTagName, setActiveTagName] = useState()
    const setTagDisplay = async (name) => {

        const tag = tags.find((ta) => ta.name === name);
        const user_response = await sendRequest('../data/users.json');

        console.log(user_response)
        console.log(tag)

        setActiveTagName(tag?.name)

        let users = [];
        tag?.members.forEach((item) => {
            let finduser = user_response.find((ui) => ui.nick === item)
            users.push(finduser)
        });

        setUsersData(users)
    }
 
    const loadMore = async () => {
        const newStartLimit = endLimit; 
        const newEndLimit = endLimit + limit; 
        
        try {
          const response = await sendRequest('../data/users.json');
          
          const result = response.slice(newStartLimit, newEndLimit); 
          const updatedList = [...usersData, ...result];
          
          setStartLimit(newStartLimit); 
          setEndLimit(newEndLimit);
          setUsersData(updatedList);
        } catch (err) {
          console.log(err);
        }
    }

    const aff_sub = localStorage.getItem('transaction_id')
    const aff_sub1 = localStorage.getItem('aff_sub1')
    const aff_sub2 = localStorage.getItem('aff_sub2')
    const aff_sub3 = localStorage.getItem('aff_sub3')
    const aff_sub4 = localStorage.getItem('aff_sub4')
    const aff_sub5 = localStorage.getItem('aff_sub5')

    const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}&transaction_id=${aff_sub}`;

    const [customFullscreen, setCustomFullscreen] = useState(false);  

    return (
        <>
        <IframeModal display={iframeBtn} strictly='on' custom_fullscreen={customFullscreen} onClose={() => {
            setIframeBtn(false)
			setCustomFullscreen(false)
            localStorage.setItem('iframeactive', false)
        }} />

        <div className="dashboard-right addbordercontent">
            {loading && <LoadingSpinner asOverlay />}

            <h3 className="db-title"><i className="fa-solid fa-users"></i> Members </h3> <hr />

            <div className="search-bytag addbordercontent-x">
                {activeTagName && (
                    <>
                    <Button variant="danger" onClick={() => getUsersData()} className="float-end" size="sm">reset filters</Button>
                    </>
                )}

                <div className="wrapScroll">
                <p>Suggested tags:</p>
                {
                    tags && tags.map((tg, index) =>{
                        let act;
                        activeTagName === tg.name ? act = "active" : null

                        return (
                            <React.Fragment key={`tg` + index}>
                                <div className="d-inline">
                                <Button variant="dark" className={act} onClick={() => setTagDisplay(tg.name)} size="sm">{tg.name}</Button>
                                </div>
                            </React.Fragment>
                        )
                    })
                }
                </div>
            </div>

            <Row className="m-1">
                    {!loading && usersData && usersData.map((user, index) => {
                        return (
                        <React.Fragment key={index}>

                        {index % 10 == 0 && (
                            <>
                            <Col sm={12} style={{ height: "300px" }}>
                            <div id="awe-customiframe-container" style={{ position: "relative" }}><iframe src="//pt.wmptctl.com/cifra?psid=blumediala&psprogram=pps&pstool=212_1&site=bimbim&cobrandid=&campaign_id=122582&category=freemium&row=1&column=2&background=D3007B&fill=0&border=0&model=insidehover&modelColor=0&modelFill=0&wide=1&padding=6px&width=0&height=0&imageWidth=0&imageHeight=0&stream=1&start=random&performerList=&subaffid={SUBAFFID}&legacyRedirect=1" scrolling="no" align="middle" frameBorder="no" allowtransparency="true" marginHeight="0" marginWidth="0" style={{ position: "absolute", left: "0", width: "100%", height: "300px", background: "transparent" }}></iframe><script src="//pt.wmptctl.com/cifra/script?id=awe-customiframe-container&row=1&column=2&border=0&wide=1&padding=6px&model=insidehover&width=0&height=0&imageWidth=0&imageHeight=0&stream=1&start=1&legacyRedirect=1"></script></div>
                            </Col>
                            </>
                        )}

                        <Col sm={6}>
                            <div className="user-block position-relative pb-3" id={`user-` + index}>
                                <Row>
                                    <Col sm={6}> <div className="user-block-img"> <img src={user?.image} alt={user?.name} style={{ width: "100%", height: "100%", objectFit: "cover" }} className="img-fluid" /> </div> </Col>
                                    <Col sm={6}>
                                        <p><i className="fa-solid fa-user"></i> {user?.name}</p>
                                        <p><i className="fa-solid fa-calendar"></i> {user?.age} years <small>old</small> </p>
                                        <p>{user?.status === "online" ? <span className="online"><i className="fa-solid fa-signal"></i> Online</span> : <span className="offline"><i className="fa-solid fa-signal"></i> Offline</span>}</p>
                                    </Col>
                                    <hr />
                                    <Col sm={12} className="text-center">
                                        <Link  to={`/dashboard/member/${user?.nick}`}><Button variant="dark" className="m-1"> VIEW PROFILE </Button></Link>
                                        
                                        <Button variant="dark" onClick={() => {
                                            setIframeBtn(true)
                                            setCustomFullscreen(true)
                                            localStorage.setItem('iframeactive', true)
                                        }} className="m-1"> CHAT NOW </Button>

                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        </React.Fragment>
                        );
                })}

                {!loading && userLength && userLength >= (limit + endLimit) ? (
                    <>
                    <div className="members-buttons-footer text-center">
                        {/* <Button onClick={() => loadMore()} variant="dark" className="mt-2 w-50" size="lg"> LOAD MORE </Button>     */}
                        <a href={`https://track.gorillatracker.com/97c2e9c0-3ac4-451e-9c2c-1b1f930bbc95${link}`} target="_blank">
                            <Button variant="dark" className="mt-2 w-50" size="lg"> LOAD MORE </Button>
                        </a>
                    </div>    

                    <Row className="mt-2">
                        <Col sm={6}>
                            <a href={`https://track.gorillatracker.com/97c2e9c0-3ac4-451e-9c2c-1b1f930bbc95${link}`} target="_blank">
                            <Button variant="dark" className="w-100">Fuck Now</Button> 
                            </a>
                        </Col>
                        <Col sm={6}>
                            <a href={`https://www.gorillatrk1.com/3J67C/QTDKJQ/${link}`} target="_blank">
                            <Button variant="dark" className="w-100">Live Cams</Button> 
                            </a>
                        </Col>
                        <Col sm={12} className="mt-4">
                            <Link to="/dashboard/hotornot">
                            <Button variant="dark" className="w-100">Play the Hot or Not Game!</Button> 
                            </Link>
                        </Col>
                    </Row>
                    </>
                ) : null}
            </Row>
        </div>
        </>
    )
}

export default DashboardMembers;