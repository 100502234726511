import axios from "axios";

export const getMoarCount = async () => {
    try {
        const res = await axios.get('https://gorillamediallc.com/bangbasetesting/api/getmoarcount');
        console.log(res.data)
        return res.data.count;
    } catch (err) {
        console.log("AXIOS ERROR: ", err);
    }
};

export const addMoarCount = async () => {
    try {
        const res = await axios.get('https://gorillamediallc.com/bangbasetesting/api/addmoarcount');
        console.log(res.data)
    } catch (err) {
        console.log("AXIOS ERROR: ", err);
    }
};

export const getAdamCount = async () => {
    try {
        const res = await axios.get('https://gorillamediallc.com/bangbasetesting/api/getadamcount');
        console.log(res.data)
        return res.data.count;
    } catch (err) {
        console.log("AXIOS ERROR: ", err);
    }
};

export const addAdamCount = async () => {
    try {
        const res = await axios.get('https://gorillamediallc.com/bangbasetesting/api/addadamcount');
        console.log(res.data)
    } catch (err) {
        console.log("AXIOS ERROR: ", err);
    }
};

