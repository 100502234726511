import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Row, Col, Container, Navbar, Nav, Button, Modal } from 'react-bootstrap';

import AOS from "aos";
import "aos/dist/aos.css";

import CreateAccount from './CreateAccount';
import { useHttpClient } from '../shared/http-hook';

import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { hashString } from 'react-hash-string';

import LoadingSpinner from './LoadingSpinner';

import { runEverflow } from './helpers/EFhelpers';
import { getMoarCount, addMoarCount, getAdamCount, addAdamCount } from './helpers/ApiHelpers';

const Landing = () => {
    const [usersData, setUsersData] = useState();
    const { sendRequest } = useHttpClient();

    const [userInfo, setUserInfo] = useState();

    const getInfo = async () => {
        try {
            const response = await sendRequest('https://geolocation-db.com/json/');
            setUserInfo(response)
        } catch (err) {
            console.log(err)
        }
    }

    const getUsersData = async () => {
        try {
            const response = await sendRequest('./data/users.json');
            const result = response.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 6);
            
            setUsersData(result)
        } catch (err) {
            console.log(err);
        }
    }

    
    useEffect(() => {
        getInfo();
        getUsersData();
    }, []);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);


    const createHandler = (info) => {
        console.log('axios call body empty')
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const autologin = searchParams.get("autologin")
    const autoemail = searchParams.get("autoemail")
    const autousername = searchParams.get("autousername")
    const autoage = searchParams.get("autoage")
    const [alLoading, setAlLoading] = useState(false)
	
    const transaction_id = searchParams.get("aff_sub")
    const aff_sub1 = searchParams.get("aff_sub1");
    const aff_sub2 = searchParams.get("aff_sub2");
    const aff_sub3 = searchParams.get("aff_sub3");
    const aff_sub4 = searchParams.get("aff_sub4");
    const aff_sub5 = searchParams.get("aff_sub5");

    const [show, setShow] = useState(autologin === "on" ? false : true);
    const handleShow = () => {
        setShow(true); 
    }

    function openInNewTab(href) {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: href,
        }).click();
    }

    // Everflow Helpers
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const ef = urlParams.get('efpid');
    const sub1 = urlParams.get('sub1');
    const ref = urlParams.get('ref')


    // global 
    function getRandomBirthdate(age) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        
        const birthYear = currentYear - age;
      
        const birthMonth = Math.floor(Math.random() * 12) + 1;
      
        const birthDay = Math.floor(Math.random() * 28) + 1;
      
        const formattedDate = `${birthMonth}/${birthDay}/${birthYear}`;
      
        return formattedDate;
    }
      
    const globalLead = async (url, email) => {
        const randomBirthdate = autoage ? getRandomBirthdate(autoage) : 18;
  
        try {
            const response = await axios.get(`https://prestigeww.waypointsoftware.io/capture.php?xAuthentication=5feca4f1951112834804944ccbf5f3f4&email=${email}&PostURL=${url}&DOB=${randomBirthdate}`)
        } catch (err) {
            console.log(err)
        }
    }

    const createMailWizz = async (ip) => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/createmailwizz',
            'POST',
            JSON.stringify({
                username: autousername ? autousername : "Newbie",
                email: autoemail,
                years: autoage ? autoage : 18,
                country_code: userInfo ? userInfo.country_code : '',
                ip: ip,
                aff_sub2: aff_sub2,
                AFFID: aff_sub5
            }),
            {
              'Content-Type': 'application/json'
            }
          );       
          
          console.log(response)

        } catch (err){
            console.log(err);
        }
    }

    const createMailWizzCustom = async (ip, list) => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/createmailwizzcustom',
            'POST',
            JSON.stringify({
                username: autousername ? autousername : "Newbie",
                email: autoemail,
                years: autoage ? autoage : 18,
                country_code: userInfo ? userInfo.country_code : '',
                ip: ip,
                aff_sub2: aff_sub2,
                AFFID: aff_sub5,
                list: list
            }),
            {
              'Content-Type': 'application/json'
            }
          );            
          console.log(response)

        } catch (err){
            console.log(err);
        }
    }


    const createDa = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createda',
              'POST',
              JSON.stringify({
                  username: autousername ? autousername : "Newbie",
                  email: autoemail,
                  ip: ip,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );            

            const formatdata = response?.data?.split("|");
            const url = formatdata[3];
            
            if(url && url.startsWith('https://')){
                window.location.href = 'http://bangbase.app';
                openInNewTab(url)
            } else {
                await createMoar(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createFlirtRevenue = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createflirtrevenue',
              'POST',
              JSON.stringify({
                  username: autousername ? autousername : "Newbie",
                  email: autoemail,
                  ip: ip,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );            
            
          } catch (err){
              console.log(err);
          }
    }

    const createAdam = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createadam',
              'POST',
              JSON.stringify({
                email: autoemail,
                ip: ip,
                aff: aff_sub1,
              }),
              {
                'Content-Type': 'application/json'
              }
            );    
            
            const adamCount = await getAdamCount();

            if(response.data && response.data.successUrl.startsWith('https://') && adamCount < 50){
                window.location.href = 'http://bangbase.app';
                openInNewTab(response.data.successUrl)

                await addAdamCount()
            } else {
                await createTrawle(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }


    const createMirelia = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createmirelia',
              'POST',
              JSON.stringify({
                  email: autoemail,
                  ip: ip,
                  aff: aff_sub1,
              }),
              {
                'Content-Type': 'application/json'
              }
            );    
            
            if(response.responsedata) {
                const url = response.responsedata.url;
                const status = response.responsedata.status;

                if(url && url.startsWith('https://')){
                    window.location.href = 'http://bangbase.app';
                    openInNewTab(url)

                    switch (status) {
                        case "success":
                          await createMailWizzCustom(ip, "nl44891yxpf27")

                          break;
                        case "success_rec":
                          await createMailWizzCustom(ip, "vm33308k1a2c5")

                          break;
                        default:
                          return;
                    }
                      
                } else {
                    await createFreeFlirtz(ip)
                } 
            }  else {
                await createFreeFlirtz(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }
    
    const createMoar = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createmoar',
              'POST',
              JSON.stringify({
                username: "Newbie",
                email: autoemail,
                ip: ip,
                aff: aff_sub1,
                aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            const moarCount = await getMoarCount();

            if(response.data && response.data.data.auto_login.startsWith('https://') && moarCount < 150){    
                // await axios.get(`https://www.gorillatrk1.com/?nid=1737&transaction_id=${aff_sub1}&oid=499`)    
  
                window.location.href = 'http://bangbase.app';
                openInNewTab(response.data.data.auto_login)

                await addMoarCount()
            } else {
                await createMirelia(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    // const createSniper = async (ip) => {
    //     try {
    //         const response = await sendRequest(
    //           'https://gorillamediallc.com/bangbasetesting/api/createsniper',
    //           'POST',
    //           JSON.stringify({
    //             username: "Newbie",
    //             email: autoemail,
    //             ip: ip,
    //             aff: aff_sub1,
    //             aff_sub2: aff_sub2

    //           }),
    //           {
    //             'Content-Type': 'application/json'
    //           }
    //         );    

    //         console.log(response.data)    
    //         // const url = response.data.data.auto_login;

    //         // if(response.data && response.data.data.auto_login.startsWith('https://')){
    //         //     window.location.href = 'http://bangbase.app';
    //         //     openInNewTab(url)
    //         // } else {
    //         //     createFlirtingTok()
    //         // }
  
    //       } catch (err){
    //           console.log(err);
    //       }
    // }

    const createFreeFlirtz = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createfreeflirtz',
              'POST',
              JSON.stringify({
                username: "Newbie",
                email: autoemail,
                ip: ip,
                aff: aff_sub1,
                aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'http://bangbase.app';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createHornySwipe(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createHornySwipe = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createhornyswipe',
              'POST',
              JSON.stringify({
                username: "Newbie",
                email: autoemail,
                ip: ip,
                aff: aff_sub1,
                aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'http://bangbase.app';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createNaughtySwipe(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createNaughtySwipe = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createnaughtyswipe',
              'POST',
              JSON.stringify({
                username: "Newbie",
                email: autoemail,
                ip: ip,
                aff: aff_sub1,
                aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data){
                window.location.href = 'http://bangbase.app';

                const url = extractAutoLoginURL(response.data);  
                openInNewTab(url)
            } else {
                await createTrawle(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createAfmy = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createafmy',
              'POST',
              JSON.stringify({
                username: "Newbie",
                  email: autoemail,
                  ip: ip,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );    

            if(response.data && response.data.url.startsWith('https://')){
                window.location.href = 'http://bangbase.app';
                openInNewTab(response.data.url)
            } else {
                await createAdam(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createTrawle = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/createtrawle',
              'POST',
              JSON.stringify({
                  username: "Newbie",
                  email: autoemail,
                  ip: ip,
                  aff: aff_sub1,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );          
            
            if(response.data && response.data.login_url.startsWith('https://')){
                window.location.href = 'http://bangbase.app';
                openInNewTab(response.data.login_url)
            } else {
                alert('Email did not pass the cascade!')
                setAlLoading(false)
                window.open('https://www.gorillatrk1.com/3J67C/CHD91P/', '_blank');
                return; 
            }

          } catch (err){
              console.log(err);
          }
    }

    function randomGenerator() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      
        for (var i = 0; i < 10; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text.toLowerCase();
    }

    var randomstring = randomGenerator();
    var randomusername = randomGenerator();
    // const createPure = async (ip) => {
    //     try {
    //         const response = await sendRequest(
    //             'https://gorillamediallc.com/bangbasetesting/api/createpure',
    //             'POST',
    //             JSON.stringify({
    //                 uname: randomusername,
    //                 email: autoemail,
    //                 password: randomstring,
    //                 ip_address: ip,
    //                 city: userInfo?.city,
    //                 state: userInfo?.state,
    //                 aff: aff_sub1,
    //                 aff_sub2: aff_sub2
    //             }),
    //             {
    //               'Content-Type': 'application/json'
    //             }
    //           );          
              
    //           if(response.data !== "\"OK\""){
    //             createMirelia(ip)
    //           }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    //
    const saveFileUsers = async (data) => {
        try {
          await axios.post(
            'https://gorillamediallc.com/bangbasetesting/api/savefileusers',
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
      
        } catch (err) {}
      };

      const [usersList, setUsersList] = useState([])
      const fetchUsers = async () => {
        try {
          const response = await axios.get('https://gorillamediallc.com/bangbasetesting/stored/data/usersdb.json', {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          setUsersList(response.data)
        } catch (err) {
          console.log(err)
        }
      }
      useLayoutEffect(() => {
        fetchUsers();
      }, []);
      
      const checkDB = async (email) => {
        let checked;

        try {
          const result = usersList.find((user) => user.email === email);
      
          if (result) {
            checked = true;
          } else {
            checked = false;
      
            const formObject = {
              email: email
            };
      
            usersList.push(formObject)
          }
      
        } catch (err) {
          console.log(err);
        }

        return checked;
    };      

    const emailAPI = async (email) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/bangbasetesting/api/checkemailapi',
              'POST',
              JSON.stringify({
                  email: email
              }),
              {
                'Content-Type': 'application/json'
              }
            );

            return response.dataresponse;      
          } catch (err) {
              console.log(err)
          }
    }

    const setAutoLogin = async () => {
        
        if(autologin === "on"){
            setAlLoading(true)

            const ausername = autousername ? autousername : "Newbie";
            const aage = autoage ? autoage : 18;

            const emailapi = await emailAPI(autoemail);

            if(emailapi && (emailapi.valid === false || emailapi.recent_abuse === true || emailapi.deliverability === "low")){
                alert('Email did not pass the request!')
                setAlLoading(false)
                window.open('https://www.gorillatrk1.com/3J67C/CHD91P/', '_blank');
                return; 
            }

            const dbRes = await checkDB(autoemail);

            if(dbRes === true){
                alert('Email already exist in our database!')
                setAlLoading(false) 
                return;               
            }

            let ip;
            let country_code;

            try {
                await axios.get('https://geolocation-db.com/json/').then( response => {
                    ip = response.data.IPv4;
                    country_code = response.data.country_code;
                    return {ip, country_code}
                })

                localStorage.setItem('aff_sub1', aff_sub1)
                localStorage.setItem('aff_sub2', aff_sub2)
                localStorage.setItem('aff_sub3', aff_sub3)
                localStorage.setItem('aff_sub4', aff_sub4)
                localStorage.setItem('aff_sub5', aff_sub5)    

    
                localStorage.setItem('user', JSON.stringify({
                    username: ausername,
                    email: autoemail,
                    age: aage
                }))

                if(transaction_id){
                    const hash = hashString(autoemail);
                    localStorage.setItem('transaction_id', transaction_id)
                    await axios.get(`https://www.gorillatrk1.com/?nid=1737&transaction_id=${transaction_id}&adv1=${hash}&sub2=${aff_sub2}&sub3=${aff_sub3}`)
                }

                // global lead
                await saveFileUsers(usersList)

                await globalLead(window.location.hostname, autoemail)
                await createFlirtRevenue(ip)
                
                await createDa(ip)
                await createMailWizz(ip)
            } catch (err) {
                setAlLoading(false)
                console.log(err)
            } finally {
                setTimeout(() => {
                    setAlLoading(false)
                    window.location.href = "/"
                }, 10000);
            }

        }
    }

    useEffect(() => {
            setAutoLogin()
    }, [autologin]);

    const partnerlogin = searchParams.get("partner_login")
    const partnerusername = searchParams.get("u")
    const partneremail = searchParams.get("e")
    const partnerage = searchParams.get("a")
    const partnersub1 = searchParams.get("sub1")
    const partnersub2 = searchParams.get("sub2")
    const partnersub3 = searchParams.get("sub3")

    const setPartnerLogin = async () => {
        if(partnerlogin === "on") {
            setAlLoading(true)

            const user = {
                username: partnerusername,
                email: partneremail,
                years: partnerage
            }

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('transaction_id', partnersub1)
            localStorage.setItem('aff_sub2', partnersub2)
            localStorage.setItem('aff_sub3', partnersub3)

            setTimeout(() => {
                setAlLoading(false)
                window.location.href = "/"
            }, 10000);
        }
    }

    useEffect(() => {
        setPartnerLogin()
    }, [partnerlogin]);

    // demo login
    const demologin = searchParams.get("demologin")

    const setDemoLogin = async () => {
        if(demologin === "on") {
            setAlLoading(true)

            const user = {
                username: `user_${Math.floor(Math.random() * 100)}`,
                email: `user_${Math.floor(Math.random() * 100)}@hotmail.com`,
                years: Math.floor(Math.random() * (80 - 18 + 1)) + 18,
            };

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('aff_sub1', aff_sub1)
            localStorage.setItem('aff_sub2', aff_sub2)
            localStorage.setItem('aff_sub3', aff_sub3)
            localStorage.setItem('aff_sub4', aff_sub4)
            localStorage.setItem('aff_sub5', aff_sub5)   

            setTimeout(() => {
                setAlLoading(false)
                window.location.href = "/?emailsystem=on"
            }, 10000);
        }
    }

    useEffect(() => {
        setDemoLogin()
    }, [demologin]);


    function extractAutoLoginURL(responseData) {
        const regex = /auto=([^&]+)/;
        const match = responseData.match(regex);
        
        if (match && match[1]) {
          const encodedURL = match[1];
          const decodedURL = decodeURIComponent(encodedURL);
          return decodedURL;
        } else {
          return null;
        }
    }
    
    return (
        <>
            <Navbar collapseOnSelect  expand="lg" bg="dark" variant="dark" className="shadow-sm">
            <Container>
            <Navbar.Brand><img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                <Nav.Link><i className="fa-solid fa-location-crosshairs"></i> {userInfo ? userInfo.city : 'Loading...'}</Nav.Link>
                </Nav>
                <Nav>
                <Nav.Link onClick={handleShow}><i className="fa-solid fa-video"></i> Live Cams</Nav.Link>
                <Nav.Link onClick={handleShow}><i className="fa-solid fa-street-view"></i> Meet & Fuck</Nav.Link>
                <Nav.Link onClick={handleShow}><i className="fa-solid fa-users"></i> Register</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
            </Navbar>

            {alLoading && <LoadingSpinner asOverlay />}

            <div className="landing-main">
                <Container>
                    <div className="landing-main-title" data-aos="fade-up">
                        <h1>Find a hookup contact in <span className="custom-purplemain">your neighbourhood</span> <br /> Ultimate dating profile search engine</h1>
                    </div>

                    <div className="landing-page-button">
                        <Button onClick={handleShow} variant="dark" className="shadow mt-3" data-aos="fade-up">GET STARTED FOR FREE</Button>
                    </div>

                    <Row className="margin-row">
                    {usersData && usersData.map((user, index) => {
                        return (
                        <React.Fragment key={index}>
                        <Col sm={4} data-aos="fade-up">
                            <div className="user-block position-relative">
                                <div className="verified">
                                    <i className="fa-solid fa-circle-check"></i>
                                </div>
                                <Row>
                                    <Col sm={4}> <center> <img src={user.image} className="img-fluid" alt={user.name} style={{ maxHeight: "100px" }}/> </center> </Col>
                                    <Col sm={8}>
                                        <p><i className="fa-solid fa-user"></i> {user.name}</p>
                                        <p><i className="fa-solid fa-calendar"></i> {user.age} years <small>old</small> </p>
                                        <p>{user.status === "online" ? <span className="online"><i className="fa-solid fa-signal"></i> Online</span> : <span className="offline"><i className="fa-solid fa-signal"></i> Offline</span>}</p>
                                    </Col>
                                    <hr />
                                    <Col sm={12}>
                                        <Button onClick={handleShow} variant="dark" className="w-50 mb-3 rounded-0"><i className="fa-solid fa-message"></i> SEND MESSAGE</Button> 
                                        <Button onClick={handleShow} variant="danger" className="w-50 mb-3 rounded-0"><i className="fa-solid fa-heart"></i> REQUEST DATE </Button> 
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        </React.Fragment>
                        );
                    })}
                    </Row>
                </Container>
            </div>

            <Modal show={show} centered keyboard={false} backdrop="static" className="custom-modalcontent">
                <Modal.Body className="position-relative">
                    <Row className="g-0">
                        <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundSize: 'cover', height: "550px", padding: "0px" }}>
                            <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="smalllogomodaltop" /> </center>

                            <CreateAccount  onCreate={createHandler} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Landing;