import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../shared/auth-context';
import { useHttpClient } from '../../shared/http-hook';

import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import LoadingSpinner from '../LoadingSpinner';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import IframeModal from '../helpers/IframeModal';

const DashboardTopPosts = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const auth = useContext(AuthContext);
    const { sendRequest, isLoading } = useHttpClient();
    const [posts, setPosts] = useState([]);
    const [postsLength, setPostsLength] = useState(0)

    const limit = 20;
    const [startLimit, setStartLimit] = useState(0);
    const [endLimit, setEndLimit] = useState(20)

    const [iframeBtn, setIframeBtn] = useState(false);

    const fetchPosts = async () => {
        try {
            const response = await sendRequest('https://gorillamediallc.com/bangbasetesting/api/getusersposts');
            const filter = response.user_posts.filter((item) => item.type === 1);

            setPostsLength(filter.length)

            const result = filter.slice(0, endLimit)

            setPosts(result)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchPosts()

        const interval = setInterval(() => {
            fetchPosts()
        }, 300100);
        return () => clearInterval(interval);       
    }, [postsLength]);

    const loadMore = async () => {
        setStartLimit(limit + startLimit)
        setEndLimit(limit + endLimit)
    
            try {
                const response = await sendRequest('https://gorillamediallc.com/bangbasetesting/api/getusersposts');
                const filter = response.user_posts.filter((item) => item.type === 1);
        
                const result = filter.slice(startLimit, endLimit)
                const updatedList = posts.concat(result);

                setPosts(updatedList)
            } catch (err) {
                console.log(err);
            }

    }

    const createComment = async (req, res, next) => {
        const response = ["magicmarty69", "dingdongdave", "freddynz", "sebasto12", "elariozz", "donkeyz", "mitchmichaelson", "well-eqptAU", "2hot4u", "NZstud", "kiwi-plunger", "sixpackmike", "robustroger", "samtheman", "jean-luc-dick-hard", "aroused4life", "dick-pic_demagio_49", "primetimepaul_72", "alfro_ricotto", "thesecretsauce_69xx", "slavemastersteve", "sub-boy98", "california_cuddler", "sluttybumUK", "cumming4u", "CApussy_sniper", "rollin_wiv_da_punches_xox", "rocksolidgerman", "love4all", "enough2luv", "ticklishtimothy", "the_punisher", "medusamycock", "mario_34", "playfulpete", "up4anything", "letsplay69", "davidsgame22", "only4fun", "all_time_andrew"];
        const result = response.sort(function() { 
            return .5 - Math.random() 
        }).slice(0, 1);
  
        const responseComments = await sendRequest('https://gorillamediallc.com/bangbasetesting/stored/data/comments_list.json');
        const filterComments = responseComments.filter((comment) => comment.type === "posts");
        const resultComments = filterComments.sort(function() {
          return .5 - Math.random()
        }).slice(0, 1);
        
        const random = Math.floor(Math.random() * resultComments[0].comments.length);
        const random_comment = resultComments[0].comments[random];

        const responsePosts = await sendRequest('https://gorillamediallc.com/bangbasetesting/api/getusersposts');
        const filterPosts = responsePosts.user_posts.filter((item) => item.type === 1)
        const resultPosts = filterPosts.sort(function() { 
            return .5 - Math.random() 
        }).slice(0, 1);

        const date = Date.now();     

        try {
            await sendRequest(
                'https://gorillamediallc.com/bangbasetesting/api/autocomm',
                'PATCH',
                JSON.stringify({
                id: resultPosts[0]?.id,
                nick: result[0],
                comment: random_comment,
                date: date,
                }),
                {
                'Content-Type': 'application/json'
                }
            );

            fetchPosts()
        } catch (err) {
            console.log(err)
        }

    }

    const [likedPosts, setLikedPosts] = useState([]);

    const likePost = async (pid) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/likepost',
            'PATCH',
            JSON.stringify({
              id: pid,
              nick: user.username
            }),
            {
              'Content-Type': 'application/json'
            }
          );
      
          setPosts((prevPosts) => {
            return prevPosts.map((post) => {
              if (post.id === pid) {
                const updatedPost = {
                  ...post,
                  likes: [...post.likes, { user: user.username }]
                };
                setLikedPosts((prevLikedPosts) => [...prevLikedPosts, updatedPost]);
                return updatedPost;
              }
              return post;
            });
          });
        } catch (err) {
          console.log(err);
        }
      };
      
      const dislikePost = async (pid) => {
        try {
          await sendRequest(
            'https://gorillamediallc.com/bangbasetesting/api/dislikepost',
            'PATCH',
            JSON.stringify({
              id: pid,
              nick: user.username
            }),
            {
              'Content-Type': 'application/json'
            }
          );
      
          setPosts((prevPosts) => {
            return prevPosts.map((post) => {
              if (post.id === pid) {
                const updatedPost = {
                  ...post,
                  likes: post.likes.filter((like) => like.user !== user.username)
                };
                setLikedPosts((prevLikedPosts) =>
                  prevLikedPosts.filter((likedPost) => likedPost.id !== pid)
                );
                return updatedPost;
              }
              return post;
            });
          });
        } catch (err) {
          console.log(err);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
          if(posts.length > 0) createComment()
        }, 60000);
    
        return () => clearInterval(interval);
    }, [posts.length]);

    const [dataLikes, setDataLikes] = useState([]);
    const [dataLikesLength, setDataLikesLength] = useState(0);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setDataLikes('')
        setDataLikesLength(0)
    }

    const handleShow = (id) => {
        setShow(true);

        let posts_likes = posts?.filter((item) => item.id === id);
        let filter = posts_likes[0]?.likes.filter((like) => like.type === "server")

        setDataLikesLength(filter.length)
        setDataLikes(filter)
    }

    // reply
    const [reply, setReply] = useState();

    const sendReply = (e) => {
        e.preventDefault();

        if(!reply) {
            toast.error('Reply cant be empty!', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                progress: undefined,
            });
        } else {
            toast.success('Reply sent!', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                progress: undefined,
            });  
        }
        
        setReply('')
    }

    const [customFullscreen, setCustomFullscreen] = useState(false);

    const aff_sub = localStorage.getItem('transaction_id')
    const aff_sub1 = localStorage.getItem('aff_sub1')
    const aff_sub2 = localStorage.getItem('aff_sub2')
    const aff_sub3 = localStorage.getItem('aff_sub3')
    const aff_sub4 = localStorage.getItem('aff_sub4')
    const aff_sub5 = localStorage.getItem('aff_sub5')

    const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}&transaction_id=${aff_sub}`;
    
    return (
        <>
            <IframeModal  display={iframeBtn} strictly='on' custom_fullscreen={customFullscreen} onClose={() => {
                setIframeBtn(false)
                setCustomFullscreen(false)
                localStorage.setItem('iframeactive', false)
            }} />

            <Modal show={show} onHide={handleClose} className="modals-like">
                <Modal.Header closeButton>
                <Modal.Title>Post likes</Modal.Title>
                </Modal.Header>
                <Modal.Body className="addbordercontent-likes">

                    {dataLikes && dataLikesLength > 0 ? (
                        <>
                        {dataLikes && dataLikes.map((data, index) => {
                            return (
                                <React.Fragment key={`data` + index}>
                                    <div className="user-like-item">
                                    <Link to={`/dashboard/member/${data?.user?.nick}`}>
                                        {data?.user?.nick}
                                    </Link>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                        </>
                    ) : (
                        <>
                        No public likes for this post!
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <div className="dashboard-right addbordercontent position-relative">

            <h3 className="db-title"><i className="fa-solid fa-medal"></i> Top posts </h3> <hr />

            {isLoading && <LoadingSpinner />}


            {!isLoading && posts && posts.map((p, index) => {
                const isLiked = likedPosts.some((post) => post.id === p.id);

                return (
                    <React.Fragment key={`p` + index}>
                        <div className="post-body">
                        <Row>
                            <Col sm={6} className="position-relative">
                                {p.likes.some(item => item.user === user.username) || isLiked ? (
                                    <>
                                        <Button onClick={() => dislikePost(p.id)} variant="dark" className="float-end" size="lg"><i className="fa-solid fa-minus"></i></Button>
                                    </>
                                ) : (
                                    <>
                                        <Button onClick={() => likePost(p.id)} variant="dark" className="float-end" size="lg"><i className="fa-solid fa-heart-circle-bolt"></i></Button>
                                    </>
                                )}
                            
                                <Link to={`/dashboard/member/${p.author}`}>
                                    {p.author}
                                </Link> 
                                <br />
                                <small>{new Date(p.date).toDateString()}</small> <hr />

                                {p.file && (
                                    <>
                                    <Link to={`/dashboard/member/${p.author}`}>
                                    <div className="post-image-v2">
                                            <img src={p.file} className="img-fluid" style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                                    </div>
                                    </Link>
                                    </>
                                )}

                                <Button className="last-bottom-custom" variant="dark" size="lg" onClick={() => {
                                        setIframeBtn(true)
                                        setCustomFullscreen(true)
                                        localStorage.setItem('iframeactive', true)
                                }}> Chat Now </Button>
                            </Col>
                            <Col sm={6}>
                                                    <Button variant="dark" onClick={() => handleShow(p.id)} size="sm" className="float-end">
                                                            View all likes
                                                    </Button>

                                                    <label>Total likes:</label> <b>{p.likes?.length > 0 ? p.likes.length : 0}</b>
                                                    <hr />
                                                    <b>Comments</b> <br />
                                                    <small>List of latest comments.</small> <hr />

                                                    {p.comments && p.comments.length < 1 && (
                                                        <>
                                                        <p>No results!</p>
                                                        </>
                                                    )}

                                                    <div className="addbordercontent-comments">
                                                    {p.comments && p.comments.map((pc, index) => {
                                                        return (
                                                            <React.Fragment key={`pc` + index}>
                                                                <Row className="comments-profile-row">
                                                                    <Col sm={12}>
                                                                        {pc.nick} <br /> <span>{pc.comment}</span>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    </div>    

                                                    <hr />

                                                    <Form className="reply-form" onSubmit={sendReply}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.reply">
                                                            <Form.Label>Reply to this post</Form.Label>
                                                            <Form.Control type="text" value={reply ? reply : ''} onChange={(e) => setReply(e.target.value)} />
                                                            <Form.Text id="exampleForm.reply" muted>
                                                                Press enter for submit
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Form>                            
                            </Col>
                        </Row>
                        </div>
                    </React.Fragment>
                )
            })}

            {!isLoading && postsLength && postsLength >= (limit + endLimit) ? (
                    <>
                    <div className="members-buttons-footer text-center">
                        {/* <Button onClick={() => loadMore()} variant="dark" className="mt-2 w-50" size="lg"> LOAD MORE </Button>     */}
                        <a href={`https://track.gorillatracker.com/97c2e9c0-3ac4-451e-9c2c-1b1f930bbc95${link}`} target="_blank">
                            <Button variant="dark" className="mt-2 w-50" size="lg"> LOAD MORE </Button>
                        </a>
                    </div>    

                    <Row className="m-0">
                        <Col sm={6}>
                            <a href={`https://track.gorillatracker.com/97c2e9c0-3ac4-451e-9c2c-1b1f930bbc95${link}`} target="_blank">
                            <Button variant="dark" className="w-100">Fuck Now</Button> 
                            </a>
                        </Col>
                        <Col sm={6}>
                            <a href={`https://www.gorillatrk1.com/3J67C/QTDKJQ/${link}`} target="_blank">
                            <Button variant="dark" className="w-100">Live Cams</Button> 
                            </a>
                        </Col>
                        <Col sm={12} className="mt-4">
                            <Link to="/dashboard/hotornot">
                            <Button variant="dark" className="w-100">Play the Hot or Not Game!</Button> 
                            </Link>
                        </Col>
                    </Row>
                    </>
            ) : null}
            </div>
        </>
    )   
}

export default DashboardTopPosts;